// Dashboard Styles

/* Mobile 
@media only screen and (max-width: 767px) {
}*/

/* Tablet / iPad Portrait 
@media only screen and (min-width: 768px) and (max-width: 991px) {
}*/

/* Computer / Desktop / iPad Landscape 
@media only screen and (min-width: 992px) and (max-width: 1199px) {
}*/

/* Large Monitor 
@media only screen and (min-width: 1200px) and (max-width: 1919px) {
}*/

// Utility variables
// Color
$brand_primary_color: #C6002B;
//rgb(198,0,43)
$brand_black: #000;
$brand_grey: #939697;
$brand_white: #FFF;
$brand_green: #61b81e;
$brand_black_alt: #333333;
// Main Navigation
// Utility variables
$navbarMainTextColor: #717277;
$navbarMainTextHoverColor: #27292d;
$navbarMainBackgroundColor: white;
$navbarMainBorderColor: #c5cdd2;
// navbar height
$navbarHeight: 58px;
//$navbarHeight: 80px;
// mobile sidebar
$mobileSidebarBorderColor: #717277;
$mobileSidebar_background: $brand_black_alt;

.layout_default {
  background: transparent;

  :global {

    .ui.cards a.card:hover, .ui.link.card:hover, .ui.link.cards .card:hover, a.ui.card:hover {
      transform: translateY(0) !important;
    }

    .menu-logo-image {
      @media all and (-ms-high-contrast: none),
      (-ms-high-contrast: active) {
        height: auto !important;
        min-height: none !important;
        padding: 18px 0 !important;
      }
    
      @media only screen and (max-width: 767px) {
        margin: 0 auto !important;
        padding: 5px 0 0 5px !important;
        max-width: 125px !important;
      }
    }

    .pushable {
      //transform-style: preserve-3d;
      -webkit-transform: none;
      transform: none;

      >.ui.sidebar#mobile-sidebar {
        position: fixed;
        width: 100%;
        max-width: 22rem;
        max-height: 100vh;
        border-radius: 0;
        background: $mobileSidebar_background;
  
        #main-mobile-nav {
          margin: 0;
          border-radius: 0;
          a.item {
            padding-left: 30px;
            font-weight: normal;
            font-style: normal;
            font-size: 16px;
            color: #D7D7D7;
            img {
              display: block;
              margin-top: 8px;
  
              &:first-child {
                margin: 0 auto;
              }
            }
          }
          >.item {
            &.header {
              img {
                padding: 5px 0 0 5px;
                max-width: 120px;
              }
            }
            &.nav-footer-signin {
              color: white;
              .nav-footer-login {
                display: inline-block;
                padding-right: 10px;
                //border-right: 1px solid $mobileSidebarBorderColor;
                >span {
                  display: inline-block;
                  max-width: 115px;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                }
              }
              .nav-footer-signup {
                display: inline-block;
                padding-left: 10px;
                >div>.ui.button {
                  &:first-child {
                    padding: 0;
                    border: none;
                    box-shadow: none !important;
                    padding-right: 8px;
                    border-right: 1px solid white;
                  }
                  &:last-child {
                    padding: 0;
                    border: none;
                    box-shadow: none !important;
                    padding-left: 8px;
                  }
                }
              }
            }
            >.ui.button {
              font-weight: 400;
            }
            &.podcasts-menu {
              a.header {
                font-weight: normal;
                font-style: normal;
                font-size: 14px;
                letter-spacing: 1.5px;
                text-transform: uppercase;
                color: rgba(0, 0, 0, .95);
                &:hover,
                &.active {
                  color: rgba(0, 0, 0, 0.7);
                  width: 100%;
                  box-shadow: none;
                  background: rgba(0, 0, 0, .05);
                }
              }
            }
          }
          .sidebar-menu-container {
            padding-top: 0;
            margin-top: 15px;
            height: 50vh;
            overflow-y: auto;
          }
        }
      }

      >.pusher {
        transition: none;
        >.ui.segment#main-nav-container {
          padding: 0;
          height: $navbarHeight;
          background: transparent;
          z-index: 1001;
          @media only screen and (max-width: 767px) {
            height: auto;
          }
          >.ui.menu#main-nav {
            padding: 0;
            background: $navbarMainBackgroundColor;
            border-top: none;
            border-bottom: 2px solid $navbarMainBorderColor;
            >.ui.container {
              height: 100%;
              >.item {
                min-height: 58px;
                font-weight: normal;
                font-style: normal;
                font-size: 16px;
                color: $navbarMainTextColor;
                &:hover {
                  background: transparent;
                }
                &.header {
                  padding: 0;
                  border: none !important;
                  max-width: 120px;
                  margin-right: 60px;
                }
                &.mobile-navbar-menu-button {
                  @media only screen and (max-width: 767px) {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                  }
                }
              }
              >.menu {
                >.item {
                  min-height: 58px;
                  font-weight: normal;
                  font-style: normal;
                  font-size: 16px;
                  color: $navbarMainTextColor;
                  &:hover {
                    background: transparent;
                  }
                }
                &.menu-functions-wrapper {
                  >.ui.dropdown.item {
                    min-height: 58px;
                    font-weight: normal;
                    font-style: normal;
                    font-size: 16px;
                    color: $navbarMainTextColor;
                    &:hover {
                      color: $navbarMainTextHoverColor;
                    }
        
                    .item {
                      font-weight: normal;
                      font-style: normal;
                      font-size: 16px;
                      color: $navbarMainTextHoverColor;
                      &:hover {
                        text-decoration: underline;
                        background: transparent;
                      }
                    }
                  }
                  >.search-container {
                    padding-right: 0;
                    height: 100%;
                    >.ui.input {
                      width: 210px;
                      color: rgb(39, 41, 45);
                    }
                  }
                }
              }
              >.ui.dropdown.item {
                min-height: 58px;
                font-weight: normal;
                font-style: normal;
                font-size: 16px;
                color: $navbarMainTextColor;
                &:hover {
                  color: $navbarMainTextHoverColor;
                }
    
                .item {
                  font-weight: normal;
                  font-style: normal;
                  font-size: 16px;
                  color: $navbarMainTextHoverColor;
                  &:hover {
                    text-decoration: underline;
                    background: transparent;
                  }
                }
              }
              >.menu-items-fold-wrapper {
                flex: 1;
                margin: 0;
                overflow: hidden;
                >a.item {
                  padding: 0 15px;
                  margin: 0;
                  border: none;
                  &:hover {
                    color: #27292d;
                  }
                  &:first-child {
                    @media all and (-ms-high-contrast: none),
                    (-ms-high-contrast: active) {
                      height: 20px;
                    }
                  }
                  &:last-child {
                    @media all and (-ms-high-contrast: none),
                    (-ms-high-contrast: active) {
                      height: 20px;
                    }
                  }
    
                  @media all and (-ms-high-contrast: none),
                  (-ms-high-contrast: active) {
                    height: 20px;
                  }
                }
              }
            }
          }
        }

        >.ui.menu#main-navbar-mobile {
          padding: 0;
          height: $navbarHeight;
          background: white;
          border-top: none;
          box-shadow: #868686 0 4px 6px -5px;
          .header {
            margin: 0;
            padding: 10px;
            border: none;
            width: 100%;
          }
          .ui.container {
            height: 100%;
            width: auto;
          }
          &.nav-menu-scroll-enabled {
            //background: $brand_black_alt !important;
          }
          .mobile-navbar-menu-button {
            @media only screen and (max-width: 767px) {
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
              z-index: 2;
            }
          }
          .mobile-navbar-login-button {
            @media only screen and (max-width: 767px) {
              position: absolute;
              right: 0;
              top: 50%;
              transform: translateY(-50%);
              z-index: 2;
            }
            >i {
              font-size: 18px;
            }
          }
        }

        >main#main-content-container {
          @media only screen and (max-width: 767px) {
            margin-top: $navbarHeight;
          }
        }

      }
    }
  }
}