// Homepage View Styles


/* Mobile 
@media only screen and (max-width: 767px) {
}*/

/* Tablet / iPad Portrait 
@media only screen and (min-width: 768px) and (max-width: 991px) {
}*/

/* Computer / Desktop / iPad Landscape 
@media only screen and (min-width: 992px) and (max-width: 1199px) {
}*/

/* Large Monitor 
@media only screen and (min-width: 1200px) and (max-width: 1919px) {
}*/

// Utility variables
// typography
$brand_fontFamily_regular: 'Helvetica Neue', Helvetica, Arial, san-serif;
$brand_fontFamily_demi: 'Helvetica Neue', Helvetica, Arial, san-serif;
$brand_fontFamily_bold: 'Helvetica Neue', Helvetica, Arial, san-serif;
// color
$brand_red: #C6002B;
//rgb(198,0,43)
$brand_black: #000;

.login_internal {

  :global {
    .ui.card {
      >.content.card-main-content {
        >.description {
          >.ui.message {
            margin-top: 25px;
          }
          >.action-options {
            margin-top: 25px;
            &.spaced {
              display: flex;
              justify-content: space-between;
            }
          }
          >.ui.segment {
            &.avatar-wrapper {
              text-align: left;
              >h2.avatar {
                display: inline-block;
                font-size: 16px;
                >.content {
                  >.sub.header {
                    >.ui.label {
                      margin: 0;
                    }
                  }
                }
              }
            }
          }
        }
        >.content.extra {
          >.action-options {
            margin-top: 25px;
            &.spaced {
              display: flex;
              justify-content: space-between;
            }
          }
        }
      }
    }
  }
}
