// Homepage View Styles


/* Mobile 
@media only screen and (max-width: 767px) {
}*/

/* Tablet / iPad Portrait 
@media only screen and (min-width: 768px) and (max-width: 991px) {
}*/

/* Computer / Desktop / iPad Landscape 
@media only screen and (min-width: 992px) and (max-width: 1199px) {
}*/

/* Large Monitor 
@media only screen and (min-width: 1200px) and (max-width: 1919px) {
}*/

// Utility variables
// color
$brand_red: #C6002B;
//rgb(198,0,43)
$brand_black: #000;

.login_base_view {

  :global {
    .ui.segment.login-base-view {
      background-color: #282c34;
      min-height: 90vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: #f6f8f9;

      >.ui.container {
        margin-bottom: 50px;
      }

      .ui.card {
        margin: 0 auto;
        width: 100%;
        max-width: 34rem;
        min-height: 305px;
        padding: 40px 50px;
        border: none;
        border-radius: 6px;
        box-shadow: 0 1px 3px rgba(0,0,0,.15);
        >.content.card-main-content {
          padding: 0;
          >div {
            &.view-default {
              >.header {
                margin: 0 auto;
                max-width: 300px;
              }
            }
            &.view-options {
              >.header.secondary {
                margin-top: 50px;
              }
              >.description {
                >.ui.button.secondary {
                  margin-top: 20px;
                }
              }
            }
          }
          >.ui.loader {
            margin-top: 25px;
          }
        }
      }
    }
    
  }
}
