// Dashboard Styles

/* Mobile 
@media only screen and (max-width: 767px) {
}*/

/* Tablet / iPad Portrait 
@media only screen and (min-width: 768px) and (max-width: 991px) {
}*/

/* Computer / Desktop / iPad Landscape 
@media only screen and (min-width: 992px) and (max-width: 1199px) {
}*/

/* Large Monitor 
@media only screen and (min-width: 1200px) and (max-width: 1919px) {
}*/

// Utility variables
// navbar height
$navbarHeight: 80px;

.layout_dashboard {
  display: flex;
  position: relative;
  height: 100vh;

  :global {

    .pro-sidebar {
      >.pro-sidebar-inner {
        >.pro-sidebar-layout {
          >.pro-sidebar-header {
            padding: 24px 15px;
            >svg#Layer_1 {
              display: none;
            }
            >.Layer_2 {
              display: block;
              max-width: 70%;
              margin: 0 auto;
            }
            >.ui.button {
              position: absolute;
              top: 0;
              right: 0;
            }
          }
          >.pro-sidebar-content {
            .pro-menu {
              .pro-menu-item {
                > .pro-inner-item {
                  >.pro-item-content {
                    font-weight: 600;
                    color: white;
                  }
                  .pro-icon-wrapper {
                    transition: transform 250ms, background 250ms;
                    .pro-icon {
                      color: white;
                      animation: none;
                      >i {
                        margin: 0;
                        line-height: normal;
                      }
                    }
                  }
                  &:hover {
                    .pro-icon-wrapper {
                      transform: translateY(-2px);
                      background: rgba(0,0,0,0.5);
                      .pro-icon {
                        animation: none;
                        >i {
                          margin: 0;
                          line-height: normal;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          >.pro-sidebar-footer {
            padding: 20px 15px;
          }
        }
      }
      &.collapsed {
        >.pro-sidebar-inner {
          >.pro-sidebar-layout {
            >.pro-sidebar-header {
              padding: 8px;
              text-align: center;
              >.Layer_2 {
                display: none;
              }
              >svg#Layer_1 {
                display: block;
                max-width: 70%;
                margin: 0 auto;
              }
              h4 {
                font-size: 12px;
              }
              >.ui.button {
                position: relative;
                margin-top: 6px;
              }
              >.mini-profile {
                >.ui.header {
                  >.content {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }

    main {
      padding: 0;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      overflow-y: auto;

      #main-content-container {
        position: relative;
        //padding-bottom: 150px;
        min-height: calc(99vh);
  
        .ui.segment.layout-page-view-heading {
          margin: 25px 0 0 0 !important;
          //padding-bottom: 25px !important;
        
          h1 {
            color: #C6002B !important;
          }
        }
  
        .ui.segment.layout-page-view-widgets {
          margin: 0;
        }
  
        .ui.segment.layout-page-view-table-wrapper {
          padding: 0;
          margin: 0;
  
          h1 {
            color: #C6002B !important;
          }
  
          .layout-page-view-table-functions {
            >.ui.button {
              margin-right: 10px;
            }
          }
        }
  
      }
    }

    .ui.menu#main-navbar {
      padding: 0;
      margin: 0;
      height: 80px;
      background: #333c4e;
      .ui.container {
        height: 100%;
        width: 100%;
        .item {
          align-self: center !important;
        }
      }  
    }

    .ui.visible.left.sidebar~.fixed, .ui.visible.left.sidebar~.pusher {
      background: #ececec;
      width: 100vw;
      transform: translate3d(250px,0,0) scaleX(0.32) !important;
      //transform: translate3d(250px,0,0) !important;
      //transition: width 600ms, transform 500ms ease;
      overflow: visible;
      &.pusher-open {
        width: calc(100vw - 250px);
      }
      &.pusher-closed {
        width: 100vw;
      }
    }

    .ui.breadcrumb .divider {
      color: rgba(255, 255, 255, 0.4) !important;
    }

    .pushable {
      transform-style: preserve-3d;
      -webkit-transform: none;
      transform: none;

      /* Sidebar */
      >.ui.sidebar#main-sidebar {
        position: fixed;
        max-width: 260px;
        max-height: 100vh;
        border-radius: 0;
        /* background: linear-gradient(223deg, #E20606 0%, #BE0000 29%, #C6002B 100%); */

        z-index: 1;
        
        overflow-x: hidden;

        >hr {
          border: 0; 
          height: 1px;
          width: 100%;
          border-top: 1px solid rgba(0, 0, 0, 0.1);
          border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        }

        >.ui.menu#main-sidebar-nav {
          display: flex;
          flex-flow: column;

          position: relative;
          height: 100%;
          border-radius: 0;
          background: #c6002b;
          /* background: linear-gradient(223deg, #E20606 0%, #BE0000 29%, #C6002B 100%); */

          .main-sidebar-logo-header {
            margin: 0 !important;
            padding: 13px 0 0 0 !important;

            >.ui.header>.image:not(.icon), .ui.header {
              >img {
                width: 3.5em !important;
                border: 2px solid white !important;
              }
            }
          }

          .main-sidebar-items {
            flex: 1 1 auto;

            overflow-y: auto;
            overflow-x: hidden;
            -webkit-overflow-scrolling: touch;
            /* -webkit-overflow-scrolling: touch;
            overflow: scroll !important; */

            .menu .item {
              padding: 15px;
            }

            /* override navigation */
            /* selected menu item */
            .menu .active.item {
              background: #0C1E41;

              &:hover {
                background: #0C1E41;
              }
            }

          }

          .main-sidebar-footer {
            flex: 0 1 40px;
            border: none  !important;
          }

          a.item {
            /*color: black;*/
          }
        }

      }

      >.pusher {
        min-height: 100vh;
        
        >.ui.menu#main-navbar {
          padding: 0;
          margin: 0;
          height: 80px;
          background: #333c4e;
          /* height: 150px; */
          /* background: url(../static/navigation-bg.jpg);
          background-position: center center;
          background-size: cover; */
          
          .ui.container {
            height: 100%;
            width: 100%;
        
            .item {
              align-self: center !important;
            }
          }  
        }

        #main-content-container {
          position: relative;
          padding-bottom: 150px;
          min-height: calc(100vh - $navbarHeight);

          .ui.segment.layout-page-view-heading {
            margin: 25px 0 0 0 !important;
            //padding-bottom: 25px !important;
          
            h1 {
              color: #C6002B !important;
            }
          }

          .ui.segment.layout-page-view-widgets {
            margin: 0;
          }

          .ui.segment.layout-page-view-table-wrapper {
            padding: 0;
            margin: 0;

            h1 {
              color: #C6002B !important;
            }

            .layout-page-view-table-functions {
              >.ui.button {
                margin-right: 10px;
              }
            }
          }

        }

      }
    } /* end .pushable */

  }

}