.externalSessionView {
  padding: 0 !important;
  height: 100%;
  :global {
    #iframe-segment {
      padding: 0;
      height: 100%;
      >.ui.container {
        &.iframe-container {
          height: 100%;
          >iframe, object {
            height: 100%;
          }
        }
      }
    }
  }
}