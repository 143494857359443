// Utility variables
// typography
$brand_fontFamily_regular: 'Helvetica Neue', Helvetica, Arial, san-serif;
$brand_fontFamily_demi: 'Helvetica Neue', Helvetica, Arial, san-serif;
$brand_fontFamily_bold: 'Helvetica Neue', Helvetica, Arial, san-serif;

.footer {
  background: #f8f9fa !important;

  @media only screen and (max-width: 767px) {
    border-top: thin solid #ececec !important;
  }

  a {
    font-family: $brand_fontFamily_regular !important;
  }

  :global {
    .ui.container {
      >.ui.grid.footer-content {
        >.row {
          >.column {
            @media only screen and (max-width: 767px) {
              width: 50% !important;
              flex-grow: 0;
            }

            h4 {
              font-size: 13px;
              font-weight: 400;
              letter-spacing: 1.5px;
              color: #C6002B;
            }
            .ui.list .item {
              padding: 8px 0;
              font-size: 13px;
              color: #2f3133;
              &:first-child{
                padding: 0 0 8px 0;
              }
              &:last-child{
                padding: 8px 0 0 0;
              }
              &:hover {
                text-decoration: underline;
              }
            }
            &.info-column {
              @media only screen and (max-width: 767px) {
                width: 100% !important;
                flex-grow: 1;
              }
              >p {
                max-width: 260px;
                padding-top: 15px;
                font-size: 16px;
              }
              >.connect-row {
                margin-top: 50px;
                >.ui.list {
                  padding: 0;
                  margin: 0;
                  .item {
                    &:hover {
                      text-decoration: none;
                    }
                  }
                }
              }
            }
          }
        }
      }
      >.ui.grid.footer-extra-content {
        margin-top: 15px;
        >.row {
          >.column {
            &.copyright-column {
              p {
                font-size: 13px;
                color: #717277;
              }
            }
            &.more-info-column {
              @media only screen and (max-width: 767px) {
                text-align: left !important;
              }

              .ui.list {
                >a {
                  font-size: 13px;
                }
              }
            }
          }
        }
      }
    }
    .ui.image.logo-image {
      width: 200px;
      @media only screen and (max-width: 767px) {
        max-width: 80% !important;
      }
    }
    .footer-menu {
      @media only screen and (max-width: 767px) {
        display: block !important;
      }
    }
  }
}